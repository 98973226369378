<template>
  <div>
    <v-tabs
      align-with-title
    >
      <v-tabs-slider color="accent"></v-tabs-slider>
      <v-tab
        :to="{ name: 'Miscellaneous Receipts - Unit/School Code' }"
      >
      Unit/School/Code
      </v-tab>
      <v-tab
        :to="{ name: 'Miscellaneous Receipts - Received Of' }"
      >
      Received Of
      </v-tab>
    </v-tabs>
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'MiscBrowse',
  components: {
  },
};
</script>
